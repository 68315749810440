import React from 'react';
import Layout from '../components/layout';
import PageHeader from '../components/PageHeader';
import Container from '../components/Container';
import Footer from '../components/Footer';
import { Link } from 'gatsby';
export default ()=>(
  <Layout>
    <PageHeader>
      <h2 className="text-6xl">Términos de uso</h2>
      <p className="mt-2 text-sm">
        <Link to="/" className="underline">Inicio</Link>
        <i className="fas fa-chevron-right mx-2"></i>
        <Link to="/terminos-uso" className="underline">Términos de uso</Link>
      </p>
    </PageHeader>
    <Container>
      <div className="text-content mt-6">
        <p>La información contenida en este sitio y sus sitios correlacionados son propiedad de WWW.DIAGNOSUR.COM a su vez propiedad de DIAGNOSUR DIAGNOSTICOS S.A. de C.V. y se proporciona al usuario con fines informativos, educativos y de esparcimiento. La información general relacionada con la salud, productos y servicios así como el acceso a otras fuentes de información, no pretende ser en modo alguno substituto de consulta, asesoría, diagnóstico, recomendación y/o examen médico, ya sea total o parcial.</p>
        <p>DIAGNOSUR DIAGNÓSTICOS recomienda que siempre discuta cuidadosamente con su médico u otro profesional de la salud calificado antes de comenzar cualquier tipo de tratamiento. WWW.DIAGNOSUR.COM y sus proveedores de información, se preocupan por publicar información precisa, confiable y actualizada, y no garantiza ni se responsabiliza sobre la información contenida en este sitio o en los sitios correlacionados. WWW.DIAGNOSUR.COM no asume responsabilidad alguna por el uso o aplicación respecto de la información publicada en estas páginas, así como por errores, omisiones u otras discrepancias derivadas de dicha información con otras publicaciones. La información de cualquier tipo, que WWW.DIAGNOSUR.COM transmita y/o provea, será supervisada por profesionales de salud calificados, sin embargo nunca sustituirá la consulta, revisión, asesoría o recomendación de un médico.</p>
        <p>Para el caso de resultados en línea, se expide una clave cuyo uso es responsabilidad única del usuario a quien le fue proporcionada. DIAGNOSUR DIAGNOSTICOS S.A. de C.V. NO SE HACE RESPONSABLE por el uso, cualquiera que fuese, que se le dé a los resultados una vez consultados u obtenidos por el usuario.</p>
        <p>Por el simple hecho de abrir este sitio web, se entenderá como su acuerdo, aceptación y reconocimiento a los términos que aquí se establecen. Usted expresamente libera a WWW.DIAGNOSUR.COM, sus accionistas, administrativos, representantes, y demás pertenecientes a la sociedad mercantil DIAGNOSUR DIAGNOSTICOS S.A. de C.V., de toda responsabilidad derivada del uso de este sitio web y/o de la información contenida en el mismo. No tiene que usar este sitio web si no está de acuerdo con estos términos.</p>
        <p>Este sitio web contiene marcas, nombres, diseños, logotipos, signos distintivos, avisos comerciales, imágenes, textos, audiovisuales, fotografías, dibujos, así como otros derechos de propiedad industrial e intelectual amparados por la Ley de la Propiedad Industrial y por la Ley Federal del Derecho de Autor, cuya propiedad corresponde a distintas personas físicas o morales, para lo cual WWW.DIAGNOSUR.COM ha solicitado su autorización, y que en algunos casos, es propiedad única y exclusiva de WWW.DIAGNOSUR.COM y/o sus autores, por lo que está prohibida la comunicación, transmisión, utilización, producción, distribución, ofrecimiento, reproducción, imitación, copia, publicación, alteración o modificación de la información contenida en este sitio web, ya sea de manera total o parcial, sin limitación a lo establecido en las leyes aplicables.</p>
      </div>
    </Container>
    <Footer></Footer>
  </Layout>
)